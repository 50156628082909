#projects {
  min-height: calc( 100vh - 60px );
  width: 100%;
  max-width: 100vw;
  background-color: $background_light;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .projects_heading {
    width: 70%;
    font-size: 2.4rem;
    color: #262626;
    padding-bottom: 1vh;
    border-bottom: 2px solid $border_blue;
    margin-bottom: 10vh;
    margin-top: 15vh;
    padding-left: 1vw;

    @media screen and (max-width: $phone) {
      font-size: 2rem;
    }
  }

  .projects_container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10vh;

    @media screen and (max-width: $tablet_small) {
      width: 95%;
    }

    @media screen and (max-width: $tablet_xsmall ) {
      width: 90%;
    }

    @media screen and (max-width: $phone) {
      width: 95%;
    }
  }

  .projects_transition {
    width: 100%;
    height: 10vh;
    background-color: $background_light;
    display: flex;

    .transition_left {
      width: 50%;
      height: 10vh;
      background-color: $background_dark;
      clip-path: polygon(0 101%, 0 0, 100% 101%);
    }

    .transition_right {
      width: 50%;
      height: 10vh;
      background-color: $background_dark;
      clip-path: polygon(100% 101%, 100% 0, 0 101%);
    }
  }
}