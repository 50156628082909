.skill_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;

  h2 {
    font-size: 1.2rem;
    padding-top: 1vh;
    color: #404040;
  }
}

.skill_icon {
  color: $border_blue;
  font-size: 4rem;

  @media screen and (max-width: $tablet) {
    font-size: 3rem;
  }

  @media screen and (max-width: $phone) {
    font-size: 2rem;
  }
}