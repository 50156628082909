.landing_container {
  height: calc(100vh + 20vh);
  width: 100%;
  position: relative;
  z-index: 1;

  .landing_video_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  .landing_text_container {
    position: absolute;
    z-index: 2;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 20%;
    color: $title_dark;
    font-weight: bold;

    h1 {
      font-size: 3.2rem;
      text-align: center;
      text-shadow: 3px 3px 2px $shadow;

      @media screen and (max-width: $tablet_xsmall) {
        font-size: 3rem;
      }
    }

    h2 {
      padding-top: 40px;
      font-size: 2.4rem;
      text-align: center;
      text-shadow: 3px 3px 2px $shadow;

      @media screen and (max-width: $tablet_xsmall) {
        font-size: 2rem;
      }
    }

    .landing_button {
      margin-top: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      padding: 10px;
      background-color: transparent;
      border: 2px solid $border_darker;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: $background_blue;
        border: 2px solid $border_blue;
        color: whitesmoke;
        box-shadow: 2px 2px 3px $shadow;
      }
    }
  }

  .landing_fadeout {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    height: 20vh;
    width: 100%;
    max-width: 100%;
    background: linear-gradient(180deg, rgba(232, 232, 232, 0), rgba(232, 232, 232, 1));
  }
}