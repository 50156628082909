.nav_mobile {
  position: fixed;
  z-index: 998;
  width:100%;
  height: 30%;
  background-color: $background_blue;
  box-shadow: 2px 2px 3px $shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  transition: ease-in-out .4s;

  .nav_links_container {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.4rem;
    padding-top: 10%;
    color: $link_dark;

    .link {
      cursor: pointer;
  
      &:hover {
        color: $link_hover_white;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.hide_mobile_nav {
  opacity: 0%;
  top: -100%;
}