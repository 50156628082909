/* CSS reset until line 50 */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-size: 62.5%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar-track {
  background-color: #dbdbdb;
}

body::-webkit-scrollbar-thumb {
  background-color: dodgerblue;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
}

.landing_container {
  height: calc(100vh + 20vh);
  width: 100%;
  position: relative;
  z-index: 1;
}

.landing_container .landing_video_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.landing_container .landing_video_container video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.landing_container .landing_text_container {
  position: absolute;
  z-index: 2;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5% 20%;
  color: #262626;
  font-weight: bold;
}

.landing_container .landing_text_container h1 {
  font-size: 3.2rem;
  text-align: center;
  text-shadow: 3px 3px 2px lightslategray;
}

@media screen and (max-width: 601px) {
  .landing_container .landing_text_container h1 {
    font-size: 3rem;
  }
}

.landing_container .landing_text_container h2 {
  padding-top: 40px;
  font-size: 2.4rem;
  text-align: center;
  text-shadow: 3px 3px 2px lightslategray;
}

@media screen and (max-width: 601px) {
  .landing_container .landing_text_container h2 {
    font-size: 2rem;
  }
}

.landing_container .landing_text_container .landing_button {
  margin-top: 30px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.4rem;
  padding: 10px;
  background-color: transparent;
  border: 2px solid #262626;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.landing_container .landing_text_container .landing_button:hover {
  background-color: dodgerblue;
  border: 2px solid dodgerblue;
  color: whitesmoke;
  -webkit-box-shadow: 2px 2px 3px lightslategray;
          box-shadow: 2px 2px 3px lightslategray;
}

.landing_container .landing_fadeout {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
  height: 20vh;
  width: 100%;
  max-width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(232, 232, 232, 0)), to(#e8e8e8));
  background: linear-gradient(180deg, rgba(232, 232, 232, 0), #e8e8e8);
}

.nav {
  width: 100%;
  height: 60px;
  background-color: #262626;
  margin-top: -60px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 3vw;
  border-bottom: 2px solid dodgerblue;
  -webkit-transition: ease-in-out .5s;
  transition: ease-in-out .5s;
}

.nav h1 {
  font-size: 1.6rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.nav .nav_mobile_icon_container {
  display: none;
}

@media screen and (max-width: 962px) {
  .nav .nav_mobile_icon_container {
    display: block;
    color: white;
  }
}

.nav .nav_mobile_icon_container .icon {
  font-size: 1.8rem;
  cursor: pointer;
}

.nav .nav_links_conatiner {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

@media screen and (max-width: 962px) {
  .nav .nav_links_conatiner {
    display: none;
  }
}

.nav .nav_links_conatiner .link {
  -webkit-box-flex: .3;
      -ms-flex: .3;
          flex: .3;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  height: 100%;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  color: white;
}

.nav .nav_links_conatiner .link.active {
  color: dodgerblue;
}

.nav .nav_links_conatiner .link:hover {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  color: dodgerblue;
}

.hide {
  opacity: 0%;
}

.hide_icon {
  display: none;
}

.nav_mobile {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 30%;
  background-color: dodgerblue;
  -webkit-box-shadow: 2px 2px 3px lightslategray;
          box-shadow: 2px 2px 3px lightslategray;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  -webkit-transition: ease-in-out .4s;
  transition: ease-in-out .4s;
}

.nav_mobile .nav_links_container {
  height: 80%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.4rem;
  padding-top: 10%;
  color: #262626;
}

.nav_mobile .nav_links_container .link {
  cursor: pointer;
}

.nav_mobile .nav_links_container .link:hover {
  color: white;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.hide_mobile_nav {
  opacity: 0%;
  top: -100%;
}

#about {
  width: 100%;
  min-height: calc( 100vh - 60px);
  background-color: #e8e8e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 10%;
  padding-bottom: 15%;
}

#about .about_me_conatiner {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #262626;
}

@media screen and (max-width: 1920px) {
  #about .about_me_conatiner {
    width: 70%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 601px) {
  #about .about_me_conatiner {
    width: 80%;
  }
}

@media screen and (max-width: 414px) {
  #about .about_me_conatiner {
    width: 90%;
  }
}

#about .about_me_conatiner .about_me_top {
  width: 39%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10vh;
}

@media screen and (max-width: 1920px) {
  #about .about_me_conatiner .about_me_top {
    margin-top: 10vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media screen and (max-width: 1440px) {
  #about .about_me_conatiner .about_me_top {
    width: 80%;
  }
}

@media screen and (max-width: 962px) {
  #about .about_me_conatiner .about_me_top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 601px) {
  #about .about_me_conatiner .about_me_top {
    width: 90%;
  }
}

@media screen and (max-width: 414px) {
  #about .about_me_conatiner .about_me_top {
    width: 80%;
  }
}

@media screen and (max-width: 375px) {
  #about .about_me_conatiner .about_me_top {
    width: 90%;
  }
}

#about .about_me_conatiner .about_me_top .about_photo {
  width: 30%;
  border-radius: 20px;
  -webkit-box-shadow: 2px 2px 2px lightslategray;
          box-shadow: 2px 2px 2px lightslategray;
}

@media screen and (max-width: 962px) {
  #about .about_me_conatiner .about_me_top .about_photo {
    margin-bottom: 7vh;
  }
}

@media screen and (max-width: 601px) {
  #about .about_me_conatiner .about_me_top .about_photo {
    margin-bottom: 2vh;
  }
}

#about .about_me_conatiner .about_me_top .about_education_container h2 {
  font-size: 1.6rem;
  padding-bottom: 2vh;
  margin-bottom: 1vh;
  border-bottom: 2px solid dodgerblue;
  padding-left: 1vw;
}

#about .about_me_conatiner .about_me_top .about_education_container div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1vh;
}

#about .about_me_conatiner .about_me_top .about_education_container div img {
  width: 30px;
}

#about .about_me_conatiner .about_me_top .about_education_container div h3 {
  font-size: 1.2rem;
  margin-left: 1vw;
  color: #333333;
}

#about .about_me_conatiner .about_me_top .about_education_container h4 {
  font-size: 1rem;
  padding-left: calc(30px + 1vw);
  color: #404040;
}

@media screen and (max-width: 962px) {
  #about .about_me_conatiner .about_me_top .about_education_container {
    margin-top: 5vh;
  }
  #about .about_me_conatiner .about_me_top .about_education_container h2 {
    text-align: center;
  }
}

#about .about_me_conatiner .about_me_botom {
  width: 59%;
}

@media screen and (max-width: 1920px) {
  #about .about_me_conatiner .about_me_botom {
    margin-bottom: 15vh;
  }
}

@media screen and (max-width: 1440px) {
  #about .about_me_conatiner .about_me_botom {
    width: 90%;
    margin-bottom: 10vh;
  }
}

@media screen and (max-width: 601px) {
  #about .about_me_conatiner .about_me_botom {
    width: 95%;
    margin-bottom: 5vh;
  }
}

@media screen and (max-width: 414px) {
  #about .about_me_conatiner .about_me_botom {
    width: 90%;
  }
}

@media screen and (max-width: 375px) {
  #about .about_me_conatiner .about_me_botom {
    width: 95%;
  }
}

#about .about_me_conatiner .about_me_botom h2 {
  font-size: 1.6rem;
  margin-top: 2vh;
  margin-bottom: 3vh;
  padding-bottom: 2vh;
  border-bottom: 2px solid dodgerblue;
  padding-left: 1vw;
}

@media screen and (max-width: 962px) {
  #about .about_me_conatiner .about_me_botom h2 {
    text-align: center;
  }
}

#about .about_me_conatiner .about_me_botom p {
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 1vh;
  color: #404040;
}

@media screen and (max-width: 414px) {
  #about .about_me_conatiner .about_me_botom p {
    font-size: 1.1rem;
  }
}

#about .about_me_conatiner .about_me_botom p a {
  text-decoration: none;
  color: #262626;
  text-shadow: 0.1px 0.1px 0.1px lightslategray;
}

#about .about_me_conatiner .about_me_botom p a:hover {
  color: dodgerblue;
  text-shadow: .1px .1px .1px white;
}

#about .about_skills_container {
  width: 60%;
  min-height: 60vh;
  margin-top: 10vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
}

@media screen and (max-width: 1920px) {
  #about .about_skills_container {
    width: 60%;
  }
}

@media screen and (max-width: 1440px) {
  #about .about_skills_container {
    width: 70%;
  }
}

@media screen and (max-width: 962px) {
  #about .about_skills_container {
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 5vh;
  }
}

@media screen and (max-width: 768px) {
  #about .about_skills_container {
    width: 80%;
    margin-bottom: 15vh;
  }
}

@media screen and (max-width: 601px) {
  #about .about_skills_container {
    width: 85%;
  }
}

@media screen and (max-width: 414px) {
  #about .about_skills_container {
    width: 100%;
    margin-top: 5vh;
  }
}

@media screen and (max-width: 375px) {
  #about .about_skills_container {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }
}

.about_skill_card {
  width: calc(100% - 4vh);
  height: calc(100% - 4vh);
  margin: 2vh;
  background-color: #404040;
  -webkit-box-shadow: 3px 3px 2px lightslategray;
          box-shadow: 3px 3px 2px lightslategray;
  padding: 6vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 962px) {
  .about_skill_card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 4vh;
  }
}

@media screen and (max-width: 414px) {
  .about_skill_card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 3vh;
  }
}

.about_skill_card .card_text_content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 962px) {
  .about_skill_card .card_text_content {
    width: 70%;
    min-height: 25vw;
  }
}

@media screen and (max-width: 414px) {
  .about_skill_card .card_text_content {
    width: 100%;
    min-height: 25vw;
  }
}

.about_skill_card .card_text_content h1 {
  width: 70%;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 4vh;
  padding-bottom: 1vh;
  border-bottom: 2vh;
  border-bottom: 1px solid dodgerblue;
  color: white;
}

@media screen and (max-width: 962px) {
  .about_skill_card .card_text_content h1 {
    font-size: 1.3rem;
    margin-top: 0vh;
  }
}

@media screen and (max-width: 414px) {
  .about_skill_card .card_text_content h1 {
    font-size: 1.2rem;
  }
}

.about_skill_card .card_text_content p {
  margin-top: 2vh;
  line-height: 1.8;
  color: lightgrey;
  text-align: center;
  font-size: 1.1rem;
}

@media screen and (max-width: 962px) {
  .about_skill_card .card_text_content p {
    font-size: .9rem;
  }
}

@media screen and (max-width: 414px) {
  .about_skill_card .card_text_content p {
    font-size: .8rem;
  }
}

.about_icon {
  color: dodgerblue;
  font-size: 5rem;
}

@media screen and (max-width: 375px) {
  .about_icon {
    font-size: 4rem;
  }
}

#skills {
  background-color: whitesmoke;
  min-height: calc( 100vh - 60px);
  width: 100%;
  max-width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 10vh;
  padding-bottom: 15vh;
}

#skills .skills_container {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10vh;
}

@media screen and (max-width: 962px) {
  #skills .skills_container {
    width: 70%;
  }
}

@media screen and (max-width: 414px) {
  #skills .skills_container {
    width: 90%;
  }
}

#skills .skills_container h1 {
  width: 100%;
  font-size: 2.4rem;
  margin-bottom: 3vh;
  padding-bottom: 2vh;
  border-bottom: 2px solid dodgerblue;
  padding-left: 1vw;
}

@media screen and (max-width: 962px) {
  #skills .skills_container h1 {
    width: 80%;
    font-size: 2rem;
  }
}

@media screen and (max-width: 414px) {
  #skills .skills_container h1 {
    font-size: 1.6rem;
  }
}

#skills .skills_container .skill_card_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.skill_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 30px;
}

.skill_card h2 {
  font-size: 1.2rem;
  padding-top: 1vh;
  color: #404040;
}

.skill_icon {
  color: dodgerblue;
  font-size: 4rem;
}

@media screen and (max-width: 962px) {
  .skill_icon {
    font-size: 3rem;
  }
}

@media screen and (max-width: 414px) {
  .skill_icon {
    font-size: 2rem;
  }
}

#projects {
  min-height: calc( 100vh - 60px);
  width: 100%;
  max-width: 100vw;
  background-color: #e8e8e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#projects header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#projects .projects_heading {
  width: 70%;
  font-size: 2.4rem;
  color: #262626;
  padding-bottom: 1vh;
  border-bottom: 2px solid dodgerblue;
  margin-bottom: 10vh;
  margin-top: 15vh;
  padding-left: 1vw;
}

@media screen and (max-width: 414px) {
  #projects .projects_heading {
    font-size: 2rem;
  }
}

#projects .projects_container {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10vh;
}

@media screen and (max-width: 768px) {
  #projects .projects_container {
    width: 95%;
  }
}

@media screen and (max-width: 601px) {
  #projects .projects_container {
    width: 90%;
  }
}

@media screen and (max-width: 414px) {
  #projects .projects_container {
    width: 95%;
  }
}

#projects .projects_transition {
  width: 100%;
  height: 10vh;
  background-color: #e8e8e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#projects .projects_transition .transition_left {
  width: 50%;
  height: 10vh;
  background-color: #404040;
  -webkit-clip-path: polygon(0 101%, 0 0, 100% 101%);
          clip-path: polygon(0 101%, 0 0, 100% 101%);
}

#projects .projects_transition .transition_right {
  width: 50%;
  height: 10vh;
  background-color: #404040;
  -webkit-clip-path: polygon(100% 101%, 100% 0, 0 101%);
          clip-path: polygon(100% 101%, 100% 0, 0 101%);
}

.projectCard_container {
  width: calc((100% - 9%)/3);
  height: 650px;
  background-color: #404040;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5vh;
  padding: 2%;
  -webkit-box-shadow: 4px 4px 2px lightslategray;
          box-shadow: 4px 4px 2px lightslategray;
  border-bottom: 3px solid transparent;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 1440px) {
  .projectCard_container {
    height: 630px;
    width: calc((100% - 9%)/2);
  }
}

@media screen and (max-width: 962px) {
  .projectCard_container {
    height: 650px;
  }
}

@media screen and (max-width: 601px) {
  .projectCard_container {
    width: calc(100% - 9%);
    padding: 5%;
    height: 625px;
  }
}

@media screen and (max-width: 414px) {
  .projectCard_container {
    height: 500px;
  }
}

@media screen and (max-width: 375px) {
  .projectCard_container {
    height: 450px;
  }
}

.projectCard_container:hover {
  border-bottom: 3px solid dodgerblue;
  -webkit-box-shadow: 2px 2px 2px lightslategray;
          box-shadow: 2px 2px 2px lightslategray;
}

.projectCard_container .projectCard_content {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.projectCard_container .projectCard_content .projectCard_img {
  height: 25vh;
  width: 100%;
  margin-bottom: 2vh;
}

.projectCard_container .projectCard_content .projectCard_title {
  width: 90%;
  color: white;
  font-size: 1.8rem;
  line-height: 1.2;
  padding-bottom: 1vh;
  border-bottom: 1px solid dodgerblue;
  margin-bottom: 2vh;
}

@media screen and (max-width: 414px) {
  .projectCard_container .projectCard_content .projectCard_title {
    font-size: 1.2rem;
  }
}

.projectCard_container .projectCard_content .projectCard_description {
  font-size: 1.2rem;
  color: lightgrey;
  line-height: 1.4;
}

@media screen and (max-width: 414px) {
  .projectCard_container .projectCard_content .projectCard_description {
    font-size: 0.9rem;
  }
}

.projectCard_container .links_conatiner .link {
  margin-right: 20px;
  color: lightgrey;
  text-decoration: none;
  font-size: 1rem;
}

@media screen and (max-width: 414px) {
  .projectCard_container .links_conatiner .link {
    font-size: 0.9rem;
  }
}

.projectCard_container .links_conatiner .link:hover {
  color: dodgerblue;
  text-decoration: underline;
}

#contact {
  width: 100%;
  min-height: calc( 100vh - 60px);
  background-color: #404040;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#contact .contact_content_container {
  width: 100%;
  height: calc(90vh - 60px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 5vh;
}

#contact .contact_content_container .contact_content {
  width: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

@media screen and (max-width: 962px) {
  #contact .contact_content_container .contact_content {
    width: 60%;
  }
}

@media screen and (max-width: 414px) {
  #contact .contact_content_container .contact_content {
    width: 80%;
  }
}

#contact .contact_content_container .contact_content h1 {
  width: 100%;
  font-size: 2.4rem;
  padding-bottom: 2.5vh;
  border-bottom: 2px solid dodgerblue;
  margin-bottom: 5vh;
  color: white;
}

@media screen and (max-width: 1440px) {
  #contact .contact_content_container .contact_content h1 {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 962px) {
  #contact .contact_content_container .contact_content h1 {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 414px) {
  #contact .contact_content_container .contact_content h1 {
    font-size: 1.4rem;
    padding-bottom: 1.5vh;
    margin-bottom: 2vh;
  }
}

#contact .contact_content_container .contact_content p {
  width: 90%;
  font-size: 1.6rem;
  color: lightgrey;
  line-height: 1.6;
}

@media screen and (max-width: 1440px) {
  #contact .contact_content_container .contact_content p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 962px) {
  #contact .contact_content_container .contact_content p {
    font-size: 1.1rem;
    line-height: 2;
  }
}

@media screen and (max-width: 414px) {
  #contact .contact_content_container .contact_content p {
    font-size: 1rem;
  }
}

#contact .contact_content_container .contact_content p span {
  color: whitesmoke;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#contact .contact_content_container .contact_content p span:hover {
  color: dodgerblue;
}

#contact .contact_content_container .contact_content p a {
  color: whitesmoke;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-decoration: none;
}

#contact .contact_content_container .contact_content p a:hover {
  color: dodgerblue;
}

#contact .contact_content_container .contact_content .contact_socials_container {
  width: 100%;
  margin-top: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#contact .contact_copyright {
  width: 100%;
  height: 10vh;
  background-color: #262626;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#contact .contact_copyright h4 {
  font-size: .8rem;
  color: dodgerblue;
  margin-left: .5rem;
}

@media screen and (max-width: 962px) {
  #contact .contact_copyright h4 {
    font-size: .6rem;
  }
}

#contact .contact_copyright .copyright_icon {
  font-size: 1rem;
  color: dodgerblue;
}

@media screen and (max-width: 962px) {
  #contact .contact_copyright .copyright_icon {
    font-size: .7rem;
  }
}

.social_card {
  width: 20%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.social_card .social_link {
  min-width: 8vh;
  min-height: 8vh;
  text-decoration: none;
  color: dodgerblue;
  background-color: #262626;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 15px;
  -webkit-box-shadow: 1px 1px black;
          box-shadow: 1px 1px black;
  font-size: 4.5rem;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

@media screen and (max-width: 1440px) {
  .social_card .social_link {
    font-size: 4rem;
  }
}

@media screen and (max-width: 962px) {
  .social_card .social_link {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 414px) {
  .social_card .social_link {
    min-width: 6vh;
    min-height: 6vh;
    font-size: 2.8rem;
  }
}

.social_card .social_link:hover {
  color: white;
  -webkit-box-shadow: 1px 1px lightslategray;
          box-shadow: 1px 1px lightslategray;
}

.social_card .tooltip {
  width: 90%;
  margin-top: 2vh;
  text-align: center;
  padding: 10px;
  color: lightgrey;
  background-color: dodgerblue;
  font-weight: bold;
  border-radius: 10px;
  opacity: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  font-size: 1.2rem;
}

@media screen and (max-width: 1440px) {
  .social_card .tooltip {
    font-size: 1rem;
  }
}

@media screen and (max-width: 962px) {
  .social_card .tooltip {
    font-size: .8rem;
  }
}

@media screen and (max-width: 414px) {
  .social_card .tooltip {
    font-size: .6rem;
  }
}

.social_card .unhide {
  opacity: 1;
}
