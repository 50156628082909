#about {
  width: 100%;
  min-height: calc( 100vh - 60px );
  background-color: $background_light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 15%;

  .about_me_conatiner {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $title_dark;

    @media screen and (max-width: $desktop) {
      width: 70%;
      flex-direction: column;
    }

    @media screen and (max-width: $tablet_xsmall) {
      width: 80%;
    }

    @media screen and (max-width: $phone) {
      width: 90%;
    }

    .about_me_top {
      width: 39%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10vh;
      
      @media screen and (max-width: $desktop) {
        margin-top: 10vh;
        flex-direction: row;
      }
      
      @media screen and (max-width: $laptop) {
        width: 80%;
      }
      
      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }
      
      @media screen and (max-width: $tablet_xsmall) {
        width: 90%;
      }

      @media screen and (max-width: $phone) {
        width: 80%;
      }

      @media screen and (max-width: $small_phone) {
        width: 90%;
      }

      .about_photo{
        width: 30%;
        border-radius: 20px;
        box-shadow: 2px 2px 2px $shadow;

        @media screen and (max-width: $tablet) {
          margin-bottom: 7vh;
        }

        @media screen and (max-width: $tablet_xsmall) {
          margin-bottom: 2vh;
        }
      }

      .about_education_container {
        h2 {
          font-size: 1.6rem;
          padding-bottom: 2vh;
          margin-bottom: 1vh;
          border-bottom: 2px solid $border_blue;
          padding-left: 1vw;
        }
      
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1vh;
      
          img {
            width: 30px;
          }
          h3 {
            font-size: 1.2rem;
            margin-left: 1vw;
            color: $body_dark;
          }
        }
      
        h4 {
          font-size: 1rem;
          padding-left: calc(30px + 1vw);
          color:  $body_lightdark;
        }
      
        @media screen and (max-width: $tablet) {
          margin-top: 5vh;
          h2 {
            text-align: center;
          }
        }
      }
    }

    .about_me_botom {
      width: 59%;

      @media screen and (max-width: $desktop) {
        margin-bottom: 15vh;
      }

      @media screen and (max-width: $laptop) {
        width: 90%;
        margin-bottom: 10vh;
      }

      @media screen and (max-width: $tablet_xsmall) {
        width: 95%;
        margin-bottom: 5vh;
      }

      @media screen and (max-width: $phone) {
        width: 90%;
      }

      @media screen and (max-width: $small_phone) {
        width: 95%;
      }

      h2{
        font-size: 1.6rem;
        margin-top: 2vh;
        margin-bottom: 3vh;
        padding-bottom: 2vh;
        border-bottom: 2px solid $border_blue;
        padding-left: 1vw;
    
        @media screen and (max-width: $tablet) {
        text-align: center;
        }
      }
    
      p {
        font-size: 1.2rem;
        line-height: 1.7;
        margin-bottom: 1vh;
        color:  $body_lightdark;

        @media screen and (max-width: $phone) {
          font-size: 1.1rem;
        }
    
        a {
          text-decoration: none;
          color: $link_dark;
          text-shadow: .1px .1px .1px $shadow;
    
          &:hover {
            color: $link_hover_dark;
            text-shadow: .1px .1px .1px white;
          }
        }
      }
    }
  }

  .about_skills_container {
    width: 60%;
    min-height: 60vh;
    margin-top: 10vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    @media screen and (max-width: $desktop) {
      width: 60%;
    }

    @media screen and (max-width: $laptop) {
      width: 70%;
    }

    @media screen and (max-width: $tablet) {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 5vh;
    }

    @media screen and (max-width: $tablet_small) {
      width: 80%;
      margin-bottom: 15vh;
    }

    @media screen and (max-width: $tablet_xsmall) {
      width: 85%;
    }

    @media screen and (max-width: $phone) {
      width: 100%;
      margin-top: 5vh;
    }

    @media screen and (max-width: $small_phone) {
      margin-bottom: 10vh;
      margin-top: 10vh;
    }
  }
}