.nav {
  width: 100%;
  height: 60px;
  background-color: $background_darker;
  margin-top: -60px;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3vw;
  border-bottom: 2px solid $border_blue;
  transition: ease-in-out .5s;

  h1 {
    font-size: 1.6rem;
    text-decoration: none;
    color: $title_light;
    cursor: pointer;
  }

  .nav_mobile_icon_container {
    display: none;

    @media screen and (max-width: $tablet) {
      display: block;
      color: $title_light;
    }

    .icon {
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .nav_links_conatiner {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    .link {
      flex: .3;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 1.2rem;
      cursor: pointer;
      height: 100%;
      transition: 0.3s ease-in-out;
      color: $link_light;
      
        &.active {
          color: $link_hover_light;
        }
      
        &:hover {
          transition: 0.3s ease-in-out;
          color: $link_hover_light;
        }
    }

  }
}

.hide {
  opacity: 0%;
}

.hide_icon {
  display: none;
}