#skills {
  background-color: $background_lighter;
  min-height: calc( 100vh - 60px );
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 15vh;

  .skills_container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;

    // border:1px solid red;

    @media screen and (max-width: $tablet) {
      width: 70%;
    }

    @media screen and (max-width: $phone) {
      width: 90%;
    }
  
    h1 {
      width: 100%;
      font-size: 2.4rem;
      margin-bottom: 3vh;
      padding-bottom: 2vh;
      border-bottom: 2px solid $border_blue;
      padding-left: 1vw;

      @media screen and (max-width: $tablet) {
        width: 80%;
        font-size: 2rem;
      }

      @media screen and (max-width: $phone) {
        font-size: 1.6rem;
      }
    }

    .skill_card_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
    }
  }
}