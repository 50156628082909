.about_skill_card {
  width: calc(100% - 4vh);
  height: calc(100% - 4vh);
  margin: 2vh;
  background-color: $body_lightdark;
  box-shadow: 3px 3px 2px $shadow;
  padding: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: 0.5s ease-in-out;

  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 4vh;
  }

  @media screen and (max-width: $phone) {
    flex-direction: column;
    justify-content: center;
    padding: 3vh;
  }

  .card_text_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      width: 70%;
      min-height: 25vw;
    }

    @media screen and (max-width: $phone) {
      width: 100%;
      min-height: 25vw;
    }

    h1 {
      width: 70%;
      text-align: center;
      font-size: 1.5rem;
      margin-top: 4vh;
      padding-bottom: 1vh;
      border-bottom: 2vh;
      border-bottom: 1px solid $border_blue;
      color: $title_light;

      @media screen and (max-width: $tablet) {
        font-size: 1.3rem;
        margin-top: 0vh;
      }

      @media screen and (max-width: $phone) {
        font-size: 1.2rem;
      }
    }
  
    p {
      margin-top: 2vh;
      line-height: 1.8;
      color: $body_light;
      text-align: center;
      font-size: 1.1rem;

      @media screen and (max-width: $tablet) {
        font-size: .9rem;
      }

      @media screen and (max-width: $phone) {
        font-size: .8rem;
      }
    }
  }
}

.about_icon {
  color: dodgerblue;
  font-size: 5rem;

  @media screen and (max-width: $small_phone) {
    font-size: 4rem;
  }
}