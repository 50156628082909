/* CSS reset until line 50 */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// *** Font Import ***
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

// *** Overall Styles ***
* {
  font-size: 62.5%;
	box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-button {
	display: none;
}

body::-webkit-scrollbar-track {
	background-color: #dbdbdb;
}

body::-webkit-scrollbar-thumb {
	background-color: dodgerblue;
	border: 1px solid #dbdbdb;
	border-radius: 20px;
}

// *** Variables ***
// Backgrounds
$background_darker: #262626;
$background_dark: #404040;
$background_light: #e8e8e8;
$background_lighter: whitesmoke;
$background_blue: dodgerblue;

// Borders & Shadows
$border_blue: dodgerblue;
$border_darker: #262626;
$border_dark: #404040;
$shadow: lightslategray;
$dark_shadow: black;

// Text Colors
$title_dark: #262626;
$body_dark: #333333;
$body_lightdark: #404040;
$title_light: white;
$body_light: lightgrey;
$link_dark: #262626;
$link_light: white;
$link_hover_dark: dodgerblue;
$link_hover_light: dodgerblue;
$link_hover_white: white;

// Media Breakpoints
$small_phone: 375px;
$phone: 414px;
$tablet_xsmall: 601px;
$tablet_small: 768px;
$tablet: 962px;
$laptop: 1440px;
$desktop: 1920px;

// *** SCSS Component Sheet Imports ***
@import "./components/landing/landing";
@import "./components/nav/nav";
@import "./components/mobileNav/mobileNav";
@import "./components/about/about";
@import "./components/about/aboutSkill";
@import "./components/skills/skills";
@import "./components/skills/skillcard";
@import "./components/projects/projects";
@import "./components/projects/projectCard";
@import "./components/contact/contact";
@import "./components/contact/socialCard";