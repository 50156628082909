.social_card {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .social_link {
    min-width: 8vh;
    min-height: 8vh;
    text-decoration: none;
    color: dodgerblue;
    background-color: $background_darker;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 1px 1px $dark_shadow;
    font-size: 4.5rem;
    transition: 0.4s ease-in-out;

    @media screen and (max-width: $laptop) {
      font-size: 4rem;
    }

    @media screen and (max-width: $tablet) {
      font-size: 2.75rem;
    }

    @media screen and (max-width: $phone) {
      min-width: 6vh;
      min-height: 6vh;
      font-size: 2.8rem;
    }

    &:hover {
      color: white;
      box-shadow: 1px 1px $shadow;
    }
  }

  .tooltip {
    width: 90%;
    margin-top: 2vh;
    text-align: center;
    padding: 10px;
    color: lightgrey;
    background-color: dodgerblue;
    font-weight: bold;
    border-radius: 10px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    font-size: 1.2rem;

    @media screen and (max-width: $laptop) {
      font-size: 1rem;
    }

    @media screen and (max-width: $tablet) {
      font-size: .8rem;
    }

    @media screen and (max-width: $phone) {
      font-size: .6rem;
    }
  }

  .unhide {
    opacity: 1;
  }
}