#contact {
  width: 100%;
  min-height: calc( 100vh - 60px );
  background-color: $background_dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .contact_content_container {
    width: 100%;
    height: calc(90vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;

    @media screen and (max-width: $phone) {

    }

    .contact_content {
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;

      @media screen and (max-width: $tablet) {
        width: 60%;
      }

      @media screen and (max-width: $phone) {
        width: 80%;
      }

      h1 {
        width: 100%;
        font-size: 2.4rem;
        padding-bottom: 2.5vh;
        border-bottom: 2px solid $border_blue;
        margin-bottom: 5vh;
        color: $title_light;

        @media screen and (max-width: $laptop) {
          font-size: 2.2rem;
        }

        @media screen and (max-width: $tablet) {
          font-size: 1.7rem;
        }

        @media screen and (max-width: $phone) {
          font-size: 1.4rem;
          padding-bottom: 1.5vh;
          margin-bottom: 2vh;
        }

      }

      p {
        width: 90%;
        font-size: 1.6rem;
        color: $body_light;
        line-height: 1.6;

        @media screen and (max-width: $laptop) {
          font-size: 1.4rem;
        }

        @media screen and (max-width: $tablet) {
          font-size: 1.1rem;
          line-height: 2;
        }

        @media screen and (max-width: $phone) {
          font-size: 1rem;
        }

        span {
          color: whitesmoke;
          transition: 0.3s ease-in-out;
          &:hover {
            color: dodgerblue;
          }
        }
        a {
          color: whitesmoke;
          transition: 0.3s ease-in-out;
          text-decoration: none;
          &:hover {
            color: dodgerblue;
          }
        }
      }

      .contact_socials_container {
        width: 100%;
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .contact_copyright {
    width: 100%;
    height: 10vh;
    background-color: $background_darker;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: .8rem;
      color: dodgerblue;
      margin-left: .5rem;

      @media screen and (max-width: $tablet) {
        font-size: .6rem;
      }
    }

    .copyright_icon {
      font-size: 1rem;
      color: dodgerblue;

      @media screen and (max-width: $tablet) {
        font-size: .7rem;
      }
    }
  }
}